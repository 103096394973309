.dashboard__title {
    text-align: center;
    font-weight: bolder;
}

.dashboard__text {
    text-align: center;
}

.dashboard__time {
    margin-top: 1rem;
    text-align: center;
}

.dashboard__qrcode {
    padding: 1rem;
    background-color: #fff;
}

.dashboard__qrcode svg {
    display: block;
    margin: 0 auto;
}